import React, {useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import {mainTheme} from "../constants/visualThemes";

const SearchBar = (props) => {
    const [searchTerm, setSearchTerm] = useState(""); // Track the search term
    const [mergedResults, setMergedResults] = useState([]); // Store combined search results
    const [selectedItem, setSelectedItem] = useState(null);

    // Handle the search input change
    const handleSearchChange = (event, newValue) => {
        setSearchTerm(event.target.value);

        if (!event.target.value) {
            // Clear selection if search term is empty
            setMergedResults([]);
            setSelectedItem(null); // Set value to null if no search term
            return;
        }

        // Search for musicians by concatenating first_name and last_name
        const foundMusicians = props.musicians.filter((musician) =>
            `${musician.first_name} ${musician.last_name}`
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
        );

        // Search for bands
        const foundBands = props.bands.filter((band) =>
            band.name.toLowerCase().includes(event.target.value.toLowerCase())
        );

        // Merge results and mark them as either "Musician" or "Band"
        const merged = [
            ...foundMusicians.map((musician) => ({
                type: "Musician",
                label: `${musician.first_name} ${musician.last_name}`,
                id: musician.id,
                bands: musician.bands
            })),
            ...foundBands.map((band) => ({type: "Band", label: band.name, id: band.id}))
        ];

        // Update the merged results for display in the Autocomplete dropdown
        setMergedResults(merged);
    };

    // Handle selection from the Autocomplete dropdown
    const handleSelect = (event, newValue) => {
        if (newValue) {
            setSelectedItem(newValue);

            if (newValue.type === "Band") {
                // If a band is selected, update the selected band
                const band = props.bands.find((band) => band.id === newValue.id);
                if (band) {
                    props.updateSelectedBand(band);
                }
            } else if (newValue.type === "Musician") {
                // If a musician is selected, select the first band they played in
                const musician = props.musicians.find((musician) => musician.id === newValue.id);
                props.updateSelectedMusician(musician); // Update selected musician
            }
        }
    };

    return (
        <div className="bandSearchField">
            <Autocomplete
                sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: mainTheme.palette.primary.darker,
                        backgroundColor: mainTheme.palette.primary.darker,
                        opacity: 0.1,
                    },
                    "& label": {
                        "&.Mui-focused": {
                            color: mainTheme.palette.primary.main,
                        },
                    },
                }}
                disablePortal
                options={mergedResults} // Use the merged results (both musicians and bands)
                getOptionLabel={(option) => option.label} // Show the label (full name for musicians, band name for bands)
                renderOption={(props, option) => (
                    <li {...props} key={`${option.type}-${option.id}`}> {/* Ensure unique key */}
                        {option.label} <small>({option.type})</small>
                    </li>
                )}
                //value={props.selectedBand || null}
                renderInput={(params) => (
                    <TextField {...params} id="searchField" label="Meklēt grupu vai mūziķi" hiddenLabel={false}/>
                )}
                onInputChange={handleSearchChange} // Call when the user types in the input field
                onChange={handleSelect} // Handle item selection
            />
        </div>
    );
};

export default SearchBar;
